import React, { useCallback, useMemo, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  selectSectors, selectSectorFields, editSectorField, setSectorEditor, selectSectorFldIdx, createNewSector,
  deleteSector, deleteSectors, editSectorFields, editSectorsFields, selectSectorsFull, createNewSectors,
  selectSitesFull, selectSiteFldIdx, reloadNetworkElements,
} from '../../features/network/networkSlice'
import { selectUserAccess } from '../../features/login/loginSlice'
import { selectIsMyProject, selectIsSharedProject } from '../../features/projects/projectsSlice'
import {
  checkNetworkItemByBCStatus, createBCForSite, getIncludeSectorsInBC, isAllowCreateBC,
} from '../../features/bc/bcSlice'
import { setPanel } from '../../features/panel/panelSlice'
import { selectOfflineMode } from '../../features/loading/loadingSlice'
import Table from '../../components/common/Table'
import { DATA_TYPES } from '../../constants/common'
import {
  CAN_BE_REMOVED,
  SECTOR_HEIGHT_FIELD,
  SECTOR_LAT_FIELD,
  SECTOR_LNG_FIELD,
  SECTOR_NAME_FIELD,
  SECTOR_RBS_NAME_FIELD,
  SECTOR_SITE_FIELD,
  SECTOR_SITE_NAME_FIELD, SECTOR_STATUS_FIELD,
  SECTOR_TECH_FIELD,
  SECTOR_TYPES_LIST,
  SITE_NAME_FIELD,
  STATUS_ACTIVE,
  STATUS_DRAFT,
  STATUS_REMOVED,
} from '../../constants/network'
import { useConfirm } from '../common/Confirm'
import { BUSINESS_CASES, EDIT_ELEMENTS } from '../../constants/access'
import { BC_STATUS_ID } from '../../features/bc/constants'
import NewSectorForm from './dialogs/NewSector'
import { initColumnsGrid, notNull } from './utils'

const SUB_TYPES = {
  [SECTOR_LAT_FIELD]: 'lat',
  [SECTOR_LNG_FIELD]: 'lng',
  [SECTOR_STATUS_FIELD]: 'status',
}

const makeChangeBundles = (changes) => {
  const result = []
  for (const change of changes) {
    let site = result.find(({ siteId }) => siteId === change.siteId)
    if (!site) {
      site = {
        siteId: change.siteId,
        bands: [],
      }
      result.push(site)
    }
    let band = site.bands.find(({ baseStationId }) => baseStationId === change.baseStationId)
    if (!band) {
      band = {
        baseStationId: change.baseStationId,
        sectorsData: [],
      }
      site.bands.push(band)
    }
    let sector = band.sectorsData.find(({ id }) => id === change.id)
    if (!sector) {
      sector = {
        id: change.id,
      }
      band.sectorsData.push(sector)
    }
    sector.updates = change.updates
  }
  return result
}

const makeNewSectorBundles = (newRows) => {
  const result = []
  for (const newRow of newRows) {
    let site = result.find(({ siteId }) => siteId === newRow.siteId)
    if (!site) {
      site = {
        siteId: newRow.siteId,
        bands: [],
      }
      result.push(site)
    }
    let band = site.bands.find(({ tech }) => tech === newRow.tech)
    if (!band) {
      band = {
        tech: newRow.tech,
        baseStationId: newRow.baseStationId,
        sectorsData: [],
      }
      site.bands.push(band)
    }
    band.sectorsData.push(newRow.row)
  }
  return result
}

const makeSectorsBundle = (elements) => {
  const result = []
  for (const [ siteId, baseStationId, sectorId ] of elements) {
    let site = result.find(({ siteId: id }) => id === siteId)
    if (!site) {
      site = { siteId, bands: [] }
      result.push(site)
    }
    let band = site.bands.find(({ baseStationId: id }) => id === baseStationId)
    if (!band) {
      band = { baseStationId, sectorIds: [] }
      site.bands.push(band)
    }
    band.sectorIds.push(sectorId)
  }
  return result
}

const Sectors = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const refHot = useRef()

  const data = useSelector(selectSectors)
  const full = useSelector(selectSectorsFull)
  const sitesFull = useSelector(selectSitesFull)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const isMyProject = useSelector(selectIsMyProject)
  const isSharedProject = useSelector(selectIsSharedProject)
  const offlineMode = useSelector(selectOfflineMode)

  const columnsInit = useSelector(selectSectorFields)
  const columns = useMemo(() => initColumnsGrid(columnsInit, SUB_TYPES), [ columnsInit ])

  const isReadOnlyBC = useMemo(() => {
    return !userAccess[BUSINESS_CASES] || !isMyProject
  }, [ userAccess, isMyProject ])

  const [
    idFieldIdx, nameFieldIdx, lngFldIdx, latFldIdx,,,, techFieldIdx, statusFieldIdx,
    rbsFieldIdx,,
    siteFieldIdx, siteNameFieldIdx ] =
    useSelector(selectSectorFldIdx, shallowEqual)
  const [ siteIdFldIdx,,,, siteStatusFldIdx ] = useSelector(selectSiteFldIdx, shallowEqual)

  const coordFldIdxes = useMemo(() => [ lngFldIdx, latFldIdx ], [ lngFldIdx, latFldIdx ])

  const [ showNewSector, setShowNewSector ] = useState(false)

  const { renderConfirm, msg, confirm } = useConfirm()

  const contextMenuDisabled = useMemo(() => ({
    cut: !userAccess[EDIT_ELEMENTS] || isSharedProject,
    paste: !userAccess[EDIT_ELEMENTS] || isSharedProject,
    addRow: !userAccess[EDIT_ELEMENTS] || offlineMode || isSharedProject,
    deleteRow: !userAccess[EDIT_ELEMENTS] || offlineMode || isSharedProject,
  }), [ userAccess, offlineMode, isSharedProject ])

  const addRowsOptions = useMemo(() => ([ 1 ]), [])
  const initialCell = useMemo(() => ({ row: 0, col: 1 }), [])

  const goToId = useCallback((id) => {
    const table = refHot.current?.hotInstance
    const list = data.getList()
    for (let i = 0; i < list.length; i++) {
      if (list[i][idFieldIdx] === id) {
        table.selectRows(table.toVisualRow(i))
      }
    }
  }, [ data, refHot, idFieldIdx ])

  const cancelNewSector = useCallback(() => {
    setShowNewSector(false)
  }, [ setShowNewSector ])

  const addNewSector = useCallback(async ({ siteId, baseStationId, tech }) => {
    const result = await dispatch(createNewSector({ siteId, baseStationId, tech }))
    setTimeout(() => goToId(result), 50)
    setShowNewSector(false)
  }, [ dispatch, setShowNewSector, goToId ])

  const recordEdit = useCallback((row) => {
    dispatch(setSectorEditor(row))
    dispatch(setPanel('edit-sector'))
  }, [ dispatch ])

  const inlineEdit = useCallback((id, field, value, oldValue) => {
    return dispatch(editSectorField({ id, field, value, oldValue })).then((res) => {
      if (res.error) {
        dispatch(reloadNetworkElements(res))
      } else {
        if (coordFldIdxes.includes(field)) {
          const ind = full.findIndexById(id)
          // Update marker
          if (ind >= 0 && id === window.map?._activeSector) {
            const marker = window.map?._activeSectorMarker
            if (marker) {
              const lat = full.getList()[ind][latFldIdx]
              const lng = full.getList()[ind][lngFldIdx]
              marker.setLatLng({ lat, lng })
            }
          }
        }
      }
      return res
    })
  }, [ dispatch, latFldIdx, lngFldIdx, full, coordFldIdxes ])

  const inlineEditRange = useCallback((changes) => {
    if (changes.length === 1) {
      const { id, updates } = changes[0]
      return dispatch(editSectorFields({ id, updates })).then((res) => {
        if (res.error) {
          dispatch(reloadNetworkElements(res))
        }
        return res
      })
    } else if (changes.length > 1) {
      const sitesData = []
      changes.forEach(({ id, updates }) => {
        const sectorIdx = full.findIndexById(id)
        const sector = full.getList()[sectorIdx]
        const siteId = sector[siteFieldIdx]
        const baseStationId = sector[rbsFieldIdx]
        let siteData = sitesData.find(({ siteId: id }) => id === siteId)
        if (!siteData) {
          siteData = {
            siteId,
            bands: [],
          }
          sitesData.push(siteData)
        }
        let band = siteData.bands.find(({ baseStationId: id }) => id === baseStationId)
        if (!band) {
          band = {
            baseStationId,
            sectorsData: [],
          }
          siteData.bands.push(band)
        }
        let sectorData = band.sectorsData.find(({ id: sectorId }) => sectorId === id)
        if (!sectorData) {
          sectorData = {
            id,
            updates: [],
          }
          band.sectorsData.push(sectorData)
        }
        sectorData.updates = [ ...sectorData.updates, ...updates ]
      })
      return dispatch(editSectorsFields(sitesData)).then((res) => {
        if (res.error) {
          dispatch(reloadNetworkElements(res))
        }
        return res
      })
    }
  }, [ dispatch, full, rbsFieldIdx, siteFieldIdx ])

  const onAddRows = useCallback((num) => {
    if (num === 1) {
      setShowNewSector(true)
    }
  }, [ setShowNewSector ])

  const onCanDeleteRows = useCallback(() => {
    // TODO убрать после отладки удаления
    // const table = refHot.current?.hotInstance
    // const list = data.getList()
    // for (let i = rowStart; i <= rowFinish; i++) {
    //   if (!CAN_BE_REMOVED.includes(list[table.toPhysicalRow(i)][statusFieldIdx])) {
    //     return false
    //   }
    // }
    return true
  }, [])

  const onDeleteRows = useCallback(async (rowStart, rowFinish) => {
    let sectorsBlockedByStatus = 0
    let sectorsBlockedByBU = 0
    let sectorsWarnByBU = 0
    const table = refHot.current?.hotInstance
    const list = data.getList()
    let totalNumber = 0

    const ids = []
    for (let i = rowStart; i <= rowFinish; i++) {
      const index = table.toPhysicalRow(i)
      if (CAN_BE_REMOVED.includes(list[index][statusFieldIdx])) {
        // Статус сектора разрешает удаление
        ids.push([ list[index][siteFieldIdx], list[index][rbsFieldIdx], list[index][idFieldIdx] ])
      } else {
        ++sectorsBlockedByStatus
      }
      ++totalNumber
    }
    // проверка сектора на его присутствие в BC
    const blockSectors = dispatch(getIncludeSectorsInBC(ids.map(([ ,, id ]) => id))) ?? []
    const deleteIds = await ids.filter(([ siteId,, sectorId ]) => {
      if (dispatch(checkNetworkItemByBCStatus(siteId, 'site', [ BC_STATUS_ID.NEW ]))) {
        ++sectorsWarnByBU
      } else if (blockSectors.includes(sectorId)) {
        ++sectorsBlockedByBU
        return false
      }
      return true
    })

    const onDeleteSectors = () => {
      if (deleteIds.length === 1) {
        const [ siteId, baseStationId, sectorId ] = deleteIds[0]
        dispatch(deleteSector({ siteId, baseStationId, sectorId }))
      } else if (deleteIds.length > 1) {
        const sitesData = makeSectorsBundle(deleteIds)
        dispatch(deleteSectors(sitesData))
      }
    }

    const messages = []
    // Add header
    if (deleteIds.length > 0) {
      if (sectorsBlockedByStatus || sectorsBlockedByBU) {
        messages.push(`${deleteIds.length} of ${totalNumber} sectors will be deleted!`)
      }
    } else {
      messages.push(totalNumber > 1
        ? 'None of the selected sectors will be deleted!'
        : 'The selected sector won\'t be deleted!')
    }
    // Description
    if (sectorsBlockedByStatus > 0) {
      messages.push(totalNumber > 1
        ? `${sectorsBlockedByStatus} sector${sectorsBlockedByStatus === 1 ? ' is' : 's are'}  blocked by status.`
        : 'This sector is blocked by status.')
    }
    if (sectorsBlockedByBU > 0) {
      messages.push(totalNumber > 1
        ? (sectorsBlockedByBU === 1
            ? '1 sector is blocked because the project has a business case for this network element.'
            : `${sectorsBlockedByBU} sectors are blocked because the project has business cases for these network elements.`)
        : 'The project has a business case for this network element.')
    }
    if (sectorsWarnByBU > 0) {
      messages.push(totalNumber > 1
        ? (sectorsWarnByBU === 1
            ? '1 sector belongs to the site that is used in a business case in the "Created" status.'
            : `${sectorsWarnByBU} sectors belong to sites that are used in business cases in the "Created" status.`)
        : 'The project has a business case in the "Сreated" status for the site to which this sector belongs.')
    }
    // Question
    if (deleteIds.length > 0) {
      messages.push(`Do you want to delete ${deleteIds.length} sector${deleteIds.length > 1 ? 's' : ''}?`)
    }

    if (deleteIds.length > 0) {
      confirm(onDeleteSectors, { title: 'Delete Sectors', messages })
    } else {
      msg({ title: 'Delete Sectors', messages })
    }
  }, [ data, dispatch, statusFieldIdx, siteFieldIdx, rbsFieldIdx, idFieldIdx, confirm, msg ])

  const findBaseStationIdByTech = useCallback((siteId, tech) => {
    const sectorsRange = full.findRangeByValue(siteId, SECTOR_SITE_FIELD) || []
    for (const idx of sectorsRange) {
      const sector = full.getList()[idx]
      if (sector[techFieldIdx] === tech) {
        return sector[rbsFieldIdx]
      }
    }
    return null
  }, [ full, techFieldIdx, rbsFieldIdx ])

  const onImport = useCallback(async (cols, rows) => {
    const nameColumn = cols.findIndex((col) => col?.id === SECTOR_NAME_FIELD)
    const lngColumn = cols.findIndex((col) => col?.id === SECTOR_LNG_FIELD)
    const latColumn = cols.findIndex((col) => col?.id === SECTOR_LAT_FIELD)
    if (nameColumn < 0) {
      msg({ messages: [ 'Mandatory column "Sector Name" not found in dataset being imported' ] })
      return false
    }
    const siteNameColumn = cols.findIndex((col) => col?.id === SECTOR_SITE_NAME_FIELD)
    const techColumn = cols.findIndex((col) => col?.id === SECTOR_TECH_FIELD)
    const heightColumn = cols.findIndex((col) => col?.id === SECTOR_HEIGHT_FIELD)

    const lngColumnInfo = lngColumn !== -1 ? cols[lngColumn] : null
    const latColumnInfo = latColumn !== -1 ? cols[latColumn] : null
    const heightColumnInfo = heightColumn !== -1 ? cols[heightColumn] : null

    const filteredColumns = cols
      .map((column, index) => !column?.editable || column?.id === SECTOR_RBS_NAME_FIELD ? index : null)
      .filter(notNull)
    const filteredColumnsDraft = cols
      .map((column, index) =>
        (!column?.editableDraft && !column?.editable) || column?.id === SECTOR_RBS_NAME_FIELD ? index : null)
      .filter(notNull)
    const colsDraft = cols.filter((value, index) => !filteredColumnsDraft.includes(index))
    cols = cols.filter((value, index) => !filteredColumns.includes(index))
    const idxs = cols.map((col) => columns.findIndex(({ id }) => id === col?.id))
    const idxsDraft = colsDraft.map((col) => columns.findIndex(({ id }) => id === col?.id))
    const changes = []
    const newRows = []
    const problemSectorNames = []
    let noSiteCount = 0
    let noMandatoryValue = 0
    let serverError = 0
    let invalidValues = 0
    let noSiteColumn, noTechColumn
    rows.forEach((row) => {
      if (row[nameColumn]) {
        row[nameColumn] = row[nameColumn].trim().toUpperCase()
      }
      if (row[siteNameColumn]) {
        row[siteNameColumn] = row[siteNameColumn].trim().toUpperCase()
      }
      if (row[techColumn]) {
        row[techColumn] = row[techColumn].trim().toUpperCase()
      }
      const sectorName = row[nameColumn]
      const siteName = row[siteNameColumn]
      const tech = row[techColumn]
      const lng = row[lngColumn]
      const lat = row[latColumn]
      const height = row[heightColumn]

      if ((lngColumnInfo && (lng > lngColumnInfo.max || lng < lngColumnInfo.min)) ||
        (latColumnInfo && (lat > latColumnInfo.max || lat < latColumnInfo.min)) ||
        (heightColumnInfo && (height < heightColumnInfo.min || height > heightColumnInfo.max))) {
        invalidValues++
      } else if (!sectorName) {
        noMandatoryValue++
      } else {
        const sectorIdxs = full.findRangeByValue(sectorName, SECTOR_NAME_FIELD)
        if (sectorIdxs) {
          const sector = full.getList()[sectorIdxs[0]]
          const status = sector[statusFieldIdx]
          const draft = CAN_BE_REMOVED.includes(status)
          row = row.filter((value, index) => !(draft ? filteredColumnsDraft : filteredColumns).includes(index))
          const indexes = draft ? idxsDraft : idxs
          changes.push({
            id: sector[idFieldIdx],
            siteId: sector[siteFieldIdx],
            baseStationId: sector[rbsFieldIdx],
            updates: row
              .map((value, index) => indexes[index] === nameFieldIdx
                ? null
                : {
                    field: indexes[index],
                    value,
                  })
              .filter(Boolean),
          })
        } else {
          if (siteNameColumn < 0) {
            noSiteColumn = true
          }
          if (techColumn < 0) {
            noTechColumn = true
          }
          if (siteNameColumn < 0 || techColumn < 0 || !siteName || SECTOR_TYPES_LIST.indexOf(tech) < 0) {
            noMandatoryValue++
            problemSectorNames.push(sectorName)
          } else {
            const siteIdxs = sitesFull.findRangeByValue(siteName, SITE_NAME_FIELD)
            if (siteIdxs) {
              const siteId = sitesFull.getList()[siteIdxs[0]][siteIdFldIdx]
              const baseStationId = findBaseStationIdByTech(siteId, tech)
              row = row.filter((value, index) => !filteredColumnsDraft.includes(index))
              newRows.push({
                siteId,
                tech,
                baseStationId,
                row,
              })
            } else {
              noSiteCount++
              problemSectorNames.push(sectorName)
            }
          }
        }
      }
    })
    const results = []
    if (problemSectorNames.length > 10) {
      problemSectorNames.length = 10
      problemSectorNames.push('...')
    }
    if (changes.length > 0) {
      const bundledChanges = makeChangeBundles(changes)
      const result = await dispatch(editSectorsFields(bundledChanges))
      if (result.meta.requestStatus === 'fulfilled') {
        results.push(`${changes.length} Sector${changes.length > 1 ? 's' : ''} where updated`)

        changes.forEach((change) => {
          if (change.updates.some(({ field, value }) => value && coordFldIdxes.includes(field))) {
            const ind = full.findIndexById(change.id)
            if (ind >= 0) {
              const activeSector = window.map?._activeSector
              if (activeSector && activeSector === change.id) {
                const marker = window.map?._activeSectorMarker
                if (marker) {
                  const newCoordinates = change.updates.reduce((agg, { field, value }) => {
                    if (field === lngFldIdx) {
                      agg.lng = value
                    } else if (field === latFldIdx) {
                      agg.lat = value
                    }
                    return agg
                  }, {})
                  if (newCoordinates.lng && newCoordinates.lat) {
                    marker.setLatLng(newCoordinates)
                  }
                }
              }
            }
          }
        })
      } else {
        serverError += changes.length
        // TODO определить необходимость перезагрузки данных
        dispatch(reloadNetworkElements(result))
      }
    }
    if (newRows.length > 0) {
      const bundledNewRows = makeNewSectorBundles(newRows)
      const result = await dispatch(createNewSectors(idxsDraft, bundledNewRows))
      if (result.meta.requestStatus === 'fulfilled') {
        results.push(`${newRows.length} Sector${newRows.length > 1 ? 's' : ''} where added`)
      } else {
        serverError += newRows.length
      }
    }
    const messages = [
      `${results.length ? results.join(' and ') : 'No changes where made to Sectors'} by this import operation`,
    ]
    if (noSiteColumn) {
      messages.push('Mandatory column "Site Name" not found in dataset being imported')
    }
    if (noTechColumn) {
      messages.push('Mandatory column "Technology" not found in dataset being imported')
    }
    if (noSiteCount > 0) {
      messages.push(
        `Error importing ${noSiteCount} Sector${noSiteCount > 1 ? 's' : ''}: no element found for given Site Name.`,
        '(You can not create new Sites by importing data to Sectors table.)',
      )
    }
    if (serverError > 0) {
      messages.push(
        `Error importing ${serverError} Sector${serverError > 1 ? 's' : ''}: server operation failed.`,
      )
    }
    if (noMandatoryValue > 0) {
      messages.push(
        `Error importing ${noMandatoryValue} Sector${noMandatoryValue > 1 ? 's' : ''}: missed or invalid value in mandatory column.`,
      )
    }
    if (invalidValues > 0) {
      messages.push(
        `Error importing ${invalidValues} Sector${invalidValues > 1 ? 's' : ''}: imported data is invalid.`,
      )
    }
    if (problemSectorNames.length > 0) {
      messages.push(
        `Problem Sector${problemSectorNames.length > 1 ? 's' : ''}: ${problemSectorNames.join(', ')}`,
      )
    }
    msg({ messages })

    return results.length > 0
  }, [
    dispatch, columns, msg, full, idFieldIdx, nameFieldIdx, siteFieldIdx, rbsFieldIdx, siteIdFldIdx, sitesFull,
    findBaseStationIdByTech, statusFieldIdx, coordFldIdxes, latFldIdx, lngFldIdx,
  ])

  const cells = useCallback((row, col) => {
    if (!isMyProject) {
      return { readOnly: true }
    }
    const cellProperties = {}
    const physCol = col // table?.toPhysicalColumn(col)
    const physRow = row // table?.toPhysicalRow(row)
    const list = data?.getList()
    if (userAccess[EDIT_ELEMENTS] && list && list[physRow]) {
      const status = list[physRow][statusFieldIdx]
      if (CAN_BE_REMOVED.includes(status) && columns[physCol]?.editableDraft) {
        cellProperties.readOnly = false
      } else if (status === STATUS_REMOVED || (status === STATUS_ACTIVE && physCol === nameFieldIdx)) {
        cellProperties.readOnly = true
      }
    }
    return cellProperties
  }, [ data, columns, statusFieldIdx, nameFieldIdx, userAccess, isMyProject ])

  const contextMenuHidden = useCallback((name, selected) => {
    if (name === 'createBC') {
      // Allow to create BC only for one selected row with status DRAFT
      if (isReadOnlyBC) {
        return true
      }

      const selectedRows = selected.reduce((agg, selection) => {
        [ ...Array(selection[2] - selection[0] + 1).keys() ].forEach((i) => agg.add(selection[0] + i))
        return agg
      }, new Set())

      if (selectedRows.size > 1) {
        return true
      }
      const [ row ] = selectedRows
      const table = refHot.current?.hotInstance
      const physRow = table?.toPhysicalRow(row)
      const siteId = data?.getList()?.[physRow]?.[siteFieldIdx]

      const idx = sitesFull.findIndexById(siteId)
      const siteStatus = sitesFull.getList()[idx]?.[siteStatusFldIdx]
      return !siteId || !isAllowCreateBC(siteId, siteStatus, full, statusFieldIdx)
    }
    return false
  }, [ isReadOnlyBC, data, statusFieldIdx, siteFieldIdx, full, siteStatusFldIdx, sitesFull ])

  const onCallbackContextMenu = useCallback((key, selection, clickEvent, refHot) => {
    switch (key) {
      case 'createBC':
        if (selection?.length === 1) {
          const row = selection[0]?.start?.row
          if (row >= 0) {
            const table = refHot.current?.hotInstance
            const physRow = table?.toPhysicalRow(row)
            const sector = data?.getList()?.[physRow]
            if (sector) {
              const name = sector[siteNameFieldIdx]
              const id = sector[siteFieldIdx]

              const idx = sitesFull.findIndexById(id)
              const status = sitesFull.getList()[idx]?.[siteStatusFldIdx]
              dispatch(createBCForSite({ id, name, draft: status === STATUS_DRAFT }, history))
            }
          }
        }
        break
      default:
    }
  }, [ dispatch, data, sitesFull, siteNameFieldIdx, siteFieldIdx, siteStatusFldIdx, history ])

  return (
    <>
      <NewSectorForm
        show={showNewSector}
        onCancel={cancelNewSector}
        onOk={addNewSector}
      />
      {renderConfirm()}
      <Table
        data={data}
        columns={columns}
        idFieldIdx={idFieldIdx}
        statusFieldIdx={statusFieldIdx}
        onRecordEdit={recordEdit}
        onInlineEdit={inlineEdit}
        onInlineEditRange={inlineEditRange}
        onImport={onImport}
        refHot={refHot}
        dataType={DATA_TYPES.SECTORS}
        addRowsOptions={addRowsOptions}
        onAddRows={onAddRows}
        onCanDeleteRows={onCanDeleteRows}
        onDeleteRows={onDeleteRows}
        contextMenuDisabled={contextMenuDisabled}
        cells={cells}
        readOnly={!userAccess[EDIT_ELEMENTS] || !isMyProject}
        initialCell={initialCell}
        manualColumnMove
        contextMenuHidden={contextMenuHidden}
        onCallbackContextMenu={onCallbackContextMenu}
      />
    </>
  )
}

export default Sectors

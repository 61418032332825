import React, { useCallback, useMemo, useRef } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  selectSites, selectSiteFields, editSiteField, setSiteEditor, selectSiteFldIdx, createNewSite, deleteSite, deleteSites,
  selectSectorsFull, selectSectorFldIdx, editSiteFields, editSitesFields, selectSitesFull, createNewSites,
  reloadNetworkElements,
  requestSitesBySiteNames,
} from '../../features/network/networkSlice'
import Table from '../../components/common/Table'
import { DATA_TYPES } from '../../constants/common'
import {
  CAN_BE_REMOVED,
  SECTOR_SITE_FIELD,
  SITE_LAT_FIELD,
  SITE_LNG_FIELD,
  SITE_NAME_FIELD,
  SITE_STATUS_FIELD,
  STATUS_ACTIVE,
  STATUS_DRAFT,
  STATUS_REMOVED,
} from '../../constants/network'
import { createBCForSite, isAllowCreateBC, selectBCFull } from '../../features/bc/bcSlice'
import { BC_SITE_ID_FIELD } from '../../features/bc/constants'
import { selectUserAccess } from '../../features/login/loginSlice'
import {
  activeProjectId, ensureUserProject, selectIsMyProject, selectIsSharedProject,
} from '../../features/projects/projectsSlice'
import { selectOfflineMode } from '../../features/loading/loadingSlice'
import { setPanel } from '../../features/panel/panelSlice'
import { useConfirm } from '../common/Confirm'
import { BUSINESS_CASES, EDIT_ELEMENTS } from '../../constants/access'
import { initColumnsGrid, notNull } from './utils'
// import NewSiteForm from './dialogs/NewSite'

const REMOVE_STATUS_READY = -100
const REMOVE_STATUS_BLOCKED_BY_STATUS = -101
const REMOVE_STATUS_BLOCKED_BY_SECTOR_STATUS = -102
const REMOVE_STATUS_BLOCKED_BY_BU = -103

const SUB_TYPES = {
  [SITE_LAT_FIELD]: 'lat',
  [SITE_LNG_FIELD]: 'lng',
  [SITE_STATUS_FIELD]: 'status',
}

const Sites = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const refHot = useRef()

  const data = useSelector(selectSites)
  const full = useSelector(selectSitesFull)
  const columnsInit = useSelector(selectSiteFields)
  const [
    idFieldIdx, nameFieldIdx, siteLngFldIdx, siteLatFldIdx, statusFieldIdx,
  ] = useSelector(selectSiteFldIdx, shallowEqual)
  const sectorsFull = useSelector(selectSectorsFull)
  const [ ,,,,,,,, sectorStatusFldIdx ] = useSelector(selectSectorFldIdx, shallowEqual)
  const bcFull = useSelector(selectBCFull)
  const userAccess = useSelector(selectUserAccess, shallowEqual)
  const projectId = useSelector(activeProjectId)
  const isMyProject = useSelector(selectIsMyProject)
  const isSharedProject = useSelector(selectIsSharedProject)
  const offlineMode = useSelector(selectOfflineMode)

  const isReadOnlyBC = useMemo(() => {
    return !userAccess[BUSINESS_CASES] || !isMyProject
  }, [ userAccess, isMyProject ])

  const columns = useMemo(() => initColumnsGrid(columnsInit, SUB_TYPES), [ columnsInit ])

  const coordFldIdxes = useMemo(() => [ siteLngFldIdx, siteLatFldIdx ], [ siteLngFldIdx, siteLatFldIdx ])

  const initialCell = useMemo(() => ({ row: 0, col: 1 }), [])

  const { renderConfirm, msg, confirm } = useConfirm()

  // TODO: попросили прибрати форму введення координат при створенні нового сайту
  /* const [ showNewSite, setShowNewSite ] = useState(false)

  const cancelNewSite = useCallback(() => {
    setShowNewSite(false)
  }, [setShowNewSite]) */

  const contextMenuDisabled = useMemo(() => ({
    cut: !userAccess[EDIT_ELEMENTS] || isSharedProject,
    paste: !userAccess[EDIT_ELEMENTS] || isSharedProject,
    addRow: !userAccess[EDIT_ELEMENTS] || offlineMode || isSharedProject,
    deleteRow: !userAccess[EDIT_ELEMENTS] || offlineMode || isSharedProject,
  }), [ userAccess, offlineMode, isSharedProject ])

  const goToId = useCallback((id) => {
    const table = refHot.current?.hotInstance
    const list = data.getList()
    for (let i = 0; i < list.length; i++) {
      if (list[i][idFieldIdx] === id) {
        table.selectRows(table.toVisualRow(i))
      }
    }
  }, [ data, refHot, idFieldIdx ])

  const addNewSite = useCallback(async ({ lng, lat }) => {
    const result = await dispatch(createNewSite({ lng, lat }, false))
    // setShowNewSite(false)
    if (result) {
      setTimeout(() => goToId(result), 50)
    }
  }, [ dispatch, /* setShowNewSite, */goToId ])

  const recordEdit = useCallback((value) => {
    dispatch(setSiteEditor(value))
    dispatch(setPanel('edit-site'))
  }, [ dispatch ])

  const inlineEdit = useCallback((id, field, value, oldValue) => {
    return dispatch(editSiteField({ id, field, value, oldValue })).then((res) => {
      if (res.error) {
        dispatch(reloadNetworkElements(res))
      } else {
        if (coordFldIdxes.includes(field)) {
          const ind = full.findIndexById(id)
          if (ind >= 0) {
            // Update active marker (sectors move with the site)
            const activeSite = window.map?._activeSite
            if (activeSite && activeSite === id) {
              const marker = window.map?._activeSiteMarker || window.map?._activeSectorMarker
              if (marker) {
                const lat = full.getList()[ind][siteLatFldIdx]
                const lng = full.getList()[ind][siteLngFldIdx]
                marker.setLatLng({ lat, lng })
              }
            }
            dispatch(requestSitesBySiteNames({ projectId, siteNames: [ full.getList()[ind][nameFieldIdx] ] }))
          }
        }
      }
      return res
    })
  }, [ dispatch, projectId, coordFldIdxes, nameFieldIdx, full, siteLatFldIdx, siteLngFldIdx ])

  const inlineEditRange = useCallback((changes) => {
    if (changes.length === 1) {
      const { id, updates } = changes[0]
      return dispatch(editSiteFields({ id, updates })).then((res) => {
        if (res.error) {
          dispatch(reloadNetworkElements(res))
        } else {
          if (updates.some(({ field }) => coordFldIdxes.includes(field))) {
            const ind = full.findIndexById(id)
            if (ind >= 0) {
              dispatch(requestSitesBySiteNames({ projectId, siteNames: [ full.getList()[ind][nameFieldIdx] ] }))
            }
          }
        }
        return res
      })
    } else if (changes.length > 1) {
      return dispatch(editSitesFields(changes)).then((res) => {
        if (res.error) {
          dispatch(reloadNetworkElements(res))
        } else {
          const siteNames = changes.reduce((acc, change) => {
            if (change.updates.some(({ field }) => coordFldIdxes.includes(field))) {
              const ind = full.findIndexById(change.id)
              if (ind >= 0) {
                acc.push(full.getList()[ind][nameFieldIdx])
              }
            }
            return acc
          }, [])
          if (siteNames.length > 0) {
            dispatch(requestSitesBySiteNames({ projectId, siteNames }))
          }
        }
        return res
      })
    }
  }, [ dispatch, full, projectId, coordFldIdxes, nameFieldIdx ])

  const onAddRows = useCallback((num) => {
    if (num === 1) {
      return addNewSite({ lng: null, lat: null })
      // TODO: попросили прибрати форму введення координат при створенні нового сайту
      // setShowNewSite(true)
    }
  }, [ addNewSite/* setShowNewSite */ ])

  const canDeleteSite = useCallback((site) => {
    if (!CAN_BE_REMOVED.includes(site[statusFieldIdx])) {
      return REMOVE_STATUS_BLOCKED_BY_STATUS
    }
    const sectorsRange = sectorsFull.findRangeByValue(site[idFieldIdx], SECTOR_SITE_FIELD)
    if (sectorsRange) {
      const list = sectorsFull.getList()
      const sectorStatuses = sectorsRange.map((index) => list[index][sectorStatusFldIdx])
      if (!sectorStatuses.every((status) => CAN_BE_REMOVED.includes(status) || status === STATUS_REMOVED)) {
        return REMOVE_STATUS_BLOCKED_BY_SECTOR_STATUS
      }
    }
    const bcRange = bcFull.findRangeByValue(site[idFieldIdx], BC_SITE_ID_FIELD)
    if (bcRange && bcRange.length > 0) {
      return REMOVE_STATUS_BLOCKED_BY_BU
    }
    return REMOVE_STATUS_READY
  }, [ idFieldIdx, statusFieldIdx, sectorsFull, sectorStatusFldIdx, bcFull ])

  const onCanDeleteRows = useCallback(() => {
    return true
  }, [])

  const onDeleteRows = useCallback((rowStart, rowFinish) => {
    const ids = []
    const table = refHot.current?.hotInstance
    const list = data.getList()
    let totalNumber = 0
    let sitesBlockedByStatus = 0
    let sitesBlockedByBU = 0

    for (let i = rowStart; i <= rowFinish; i++) {
      const index = table.toPhysicalRow(i)
      const removeStatus = canDeleteSite(list[index])
      switch (removeStatus) {
        case REMOVE_STATUS_BLOCKED_BY_STATUS:
        case REMOVE_STATUS_BLOCKED_BY_SECTOR_STATUS:
          ++sitesBlockedByStatus
          break
        case REMOVE_STATUS_BLOCKED_BY_BU:
          ++sitesBlockedByBU
          break
        default:
          // site can be removed
          ids.push(list[index][idFieldIdx])
      }
      ++totalNumber
    }

    const onDeleteSites = () => {
      if (ids.length === 1) {
        const siteId = ids[0]
        dispatch(deleteSite({ siteId }))
      } else if (ids.length > 1) {
        dispatch(deleteSites(ids))
      }
    }

    const messages = []
    // Add header
    if (ids.length > 0) {
      if (sitesBlockedByStatus || sitesBlockedByBU) {
        messages.push(`${ids.length} of ${totalNumber} sites will be deleted!`)
      }
    } else {
      messages.push(totalNumber > 1
        ? 'None of the selected sites will be deleted!'
        : 'The selected site won\'t be deleted!')
    }
    // Add description
    if (sitesBlockedByStatus > 0) {
      messages.push(totalNumber > 1
        ? `${sitesBlockedByStatus} site${sitesBlockedByStatus === 1 ? ' is' : 's are'}  blocked by status.`
        : 'This site is blocked by status.')
    }
    if (sitesBlockedByBU > 0) {
      messages.push(totalNumber > 1
        ? (sitesBlockedByBU === 1
            ? '1 site is blocked because the project has a business case for this network element.'
            : `${sitesBlockedByBU} sites are blocked because the project has business cases for these network elements.`)
        : 'The project has a business case for this network element.')
    }
    // Question
    if (ids.length > 0) {
      messages.push(`Do you want to delete ${ids.length} site${ids.length > 1 ? 's' : ''}?`)
    }

    if (ids.length > 0) {
      const doDelete = async () => {
        const projectId = await dispatch(ensureUserProject())
        if (projectId !== '_') {
          confirm(onDeleteSites, { title: 'Delete Sites', messages })
        }
      }

      doDelete()
    } else {
      msg({ title: 'Delete Sites', messages })
    }
  }, [ data, canDeleteSite, idFieldIdx, dispatch, confirm, msg ])

  const onImport = useCallback(async (cols, rows) => {
    const nameColumn = cols.findIndex((col) => col?.id === SITE_NAME_FIELD)
    const statusColumn = cols.findIndex((col) => col?.id === SITE_STATUS_FIELD)
    const lngColumn = cols.findIndex((col) => col?.id === SITE_LNG_FIELD)
    const latColumn = cols.findIndex((col) => col?.id === SITE_LAT_FIELD)

    const lngColumnInfo = lngColumn !== -1 ? cols[lngColumn] : null
    const latColumnInfo = latColumn !== -1 ? cols[latColumn] : null

    if (nameColumn < 0) {
      msg({ messages: [ 'Mandatory column "Site Name" not found in dataset being imported' ] })
      return false
    }
    const filteredColumns = cols
      .map((column, index) => !column?.editable ? index : null)
      .filter(notNull)
    const filteredColumnsDraft = cols
      .map((column, index) => !column?.editableDraft && !column?.editable ? index : null)
      .filter(notNull)
    const colsDraft = cols.filter((value, index) => !filteredColumnsDraft.includes(index))
    cols = cols.filter((value, index) => !filteredColumns.includes(index))
    const idxs = cols.map((col) => columns.findIndex(({ id }) => id === col?.id))
    const idxsDraft = colsDraft.map((col) => columns.findIndex(({ id }) => id === col?.id))
    const changes = []
    const newRows = []
    let noMandatoryValue = 0
    let alreadyExists = 0
    let invalidValues = 0
    let serverError = 0
    const list = full.getList()
    for (let row of rows) {
      if (row[nameColumn]) {
        row[nameColumn] = row[nameColumn].trim().toUpperCase()
      }
      const siteName = row[nameColumn]
      if (!siteName) {
        noMandatoryValue++
      } else {
        if (lngColumnInfo) {
          const lng = row[lngColumn]
          if (lng > lngColumnInfo.max || lng < lngColumnInfo.min) {
            invalidValues++
            continue
          }
        }
        if (latColumnInfo) {
          const lat = row[latColumn]
          if (lat > latColumnInfo.max || lat < latColumnInfo.min) {
            invalidValues++
            continue
          }
        }
        const siteIdxs = full.findRangeByValue(siteName, SITE_NAME_FIELD)
        if (siteIdxs) {
          const importStatus = row[statusColumn]
          const status = list[siteIdxs[0]][statusFieldIdx]
          if (importStatus !== status) {
            alreadyExists++
            continue
          }
          const draft = CAN_BE_REMOVED.includes(status)
          row = row.filter((value, index) => !(draft ? filteredColumnsDraft : filteredColumns).includes(index))
          const indexes = draft ? idxsDraft : idxs
          changes.push({
            id: list[siteIdxs[0]][idFieldIdx],
            updates: row
              .map((value, index) => indexes[index] === nameFieldIdx
                ? null
                : {
                    field: indexes[index],
                    value,
                  })
              .filter(Boolean),
          })
        } else {
          row = row.filter((value, index) => !filteredColumnsDraft.includes(index))
          newRows.push(row)
        }
      }
    }
    const results = []
    const updatedCoordinatesNames = []
    if (changes.length > 0) {
      const result = await dispatch(editSitesFields(changes))
      if (result.meta.requestStatus === 'fulfilled') {
        results.push(`${changes.length} Site${changes.length > 1 ? 's' : ''} where updated`)

        changes.forEach((change) => {
          if (change.updates.some(({ field, value }) => value && coordFldIdxes.includes(field))) {
            const ind = full.findIndexById(change.id)
            if (ind >= 0) {
              updatedCoordinatesNames.push(full.getList()[ind][nameFieldIdx])
              const activeSite = window.map?._activeSite
              if (activeSite && activeSite === change.id) {
                const marker = window.map?._activeSiteMarker || window.map?._activeSectorMarker
                if (marker) {
                  const newCoordinates = change.updates.reduce((agg, { field, value }) => {
                    if (field === siteLngFldIdx) {
                      agg.lng = value
                    } else if (field === siteLatFldIdx) {
                      agg.lat = value
                    }
                    return agg
                  }, {})
                  if (newCoordinates.lng && newCoordinates.lat) {
                    marker.setLatLng(newCoordinates)
                  }
                }
              }
            }
          }
        })
      } else {
        serverError += changes.length
        // TODO: Визначити необхідність перезавантаження даних
        dispatch(reloadNetworkElements(result))
      }
    }
    if (newRows.length > 0) {
      const result = await dispatch(createNewSites(idxsDraft, newRows))
      if (result?.meta?.requestStatus === 'fulfilled') {
        results.push(`${newRows.length} Site${newRows.length > 1 ? 's' : ''} where added`)

        newRows.forEach((row) => {
          if (idxsDraft.some((fieldIdx) => coordFldIdxes.includes(fieldIdx))) {
            const nameIdx = idxsDraft.findIndex((fieldIdx) => (fieldIdx === nameFieldIdx))
            if (nameIdx >= 0) {
              updatedCoordinatesNames.push(row[nameIdx])
            }
          }
        })
      } else {
        serverError += newRows.length
      }
    }

    if (updatedCoordinatesNames.length > 0) {
      dispatch(requestSitesBySiteNames({ projectId, siteNames: updatedCoordinatesNames }))
    }

    const messages = [
      `${results.length ? results.join(' and ') : 'No changes where made to Sites'} by this import operation`,
    ]
    if (serverError > 0) {
      messages.push(
        `Error importing ${serverError} Site${serverError > 1 ? 's' : ''}: server operation failed.`,
      )
    }
    if (noMandatoryValue > 0) {
      messages.push(
        `Error importing ${noMandatoryValue} Site${noMandatoryValue > 1 ? 's' : ''}: missed or invalid value in mandatory column.`,
      )
    }
    if (alreadyExists > 0) {
      messages.push(
        `Error importing ${alreadyExists} Site${alreadyExists > 1 ? 's' : ''}: item with the same name already exist.`,
      )
    }
    if (invalidValues > 0) {
      messages.push(
        `Error importing ${invalidValues} Site${invalidValues > 1 ? 's' : ''}: imported data is invalid.`,
      )
    }
    msg({ messages })

    return results.length > 0
  }, [
    dispatch, columns, msg, full, idFieldIdx, nameFieldIdx, statusFieldIdx, coordFldIdxes, projectId,
    siteLatFldIdx, siteLngFldIdx,
  ])

  const cells = useCallback((row, col) => {
    if (!isMyProject) {
      return { readOnly: true }
    }
    const cellProperties = {}
    // const table = refHot.current?.hotInstance
    const physCol = col // table?.toPhysicalColumn(col)
    const physRow = row // table?.toPhysicalRow(row)
    const list = data?.getList()
    if (userAccess[EDIT_ELEMENTS] && list && list[physRow]) {
      const status = list[physRow][statusFieldIdx]
      if (CAN_BE_REMOVED.includes(status) && columns[physCol]?.editableDraft) {
        cellProperties.readOnly = false
      } else if (status === STATUS_REMOVED || (status === STATUS_ACTIVE && physCol === nameFieldIdx)) {
        cellProperties.readOnly = true
      }
    }
    return cellProperties
  }, [ data, columns, statusFieldIdx, nameFieldIdx, userAccess, isMyProject ])

  const contextMenuHidden = useCallback((name, selected) => {
    if (name === 'createBC') {
      // Allow to create BC only for one selected row with status DRAFT
      if (isReadOnlyBC) {
        return true
      }

      const selectedRows = selected.reduce((agg, selection) => {
        [ ...Array(selection[2] - selection[0] + 1).keys() ].forEach((i) => agg.add(selection[0] + i))
        return agg
      }, new Set())

      if (selectedRows.size > 1) {
        return true
      }
      const [ row ] = selectedRows
      const table = refHot.current?.hotInstance
      const physRow = table?.toPhysicalRow(row)
      const siteId = data?.getList()?.[physRow]?.[idFieldIdx]
      const siteStatus = data?.getList()?.[physRow]?.[statusFieldIdx]
      return !siteId || !isAllowCreateBC(siteId, siteStatus, sectorsFull, sectorStatusFldIdx)
    }
    return false
  }, [ isReadOnlyBC, data, idFieldIdx, sectorStatusFldIdx, sectorsFull, statusFieldIdx ])

  const onCallbackContextMenu = useCallback((key, selection, clickEvent, refHot) => {
    switch (key) {
      case 'createBC':
        if (selection?.length === 1) {
          const row = selection[0]?.start?.row
          if (row >= 0) {
            const table = refHot.current?.hotInstance
            const physRow = table?.toPhysicalRow(row)
            const list = data?.getList()
            const name = list[physRow]?.[nameFieldIdx]
            const id = list[physRow]?.[idFieldIdx]
            const status = list[physRow]?.[statusFieldIdx]
            dispatch(createBCForSite({ id, name, draft: status === STATUS_DRAFT }, history))
          }
        }
        break
      default:
    }
  }, [ dispatch, data, nameFieldIdx, idFieldIdx, statusFieldIdx, history ])

  return (
    <>
      {/* <NewSiteForm
        show={showNewSite}
        onCancel={cancelNewSite}
        onOk={addNewSite}
      /> */}
      {renderConfirm()}
      <Table
        data={data}
        columns={columns}
        idFieldIdx={idFieldIdx}
        statusFieldIdx={statusFieldIdx}
        onRecordEdit={recordEdit}
        onInlineEdit={inlineEdit}
        onInlineEditRange={inlineEditRange}
        onImport={onImport}
        refHot={refHot}
        dataType={DATA_TYPES.SITES}
        addRowsOptions={[ 1 ]}
        onAddRows={onAddRows}
        onCanDeleteRows={onCanDeleteRows}
        onDeleteRows={onDeleteRows}
        contextMenuDisabled={contextMenuDisabled}
        cells={cells}
        readOnly={!userAccess[EDIT_ELEMENTS] || !isMyProject}
        manualColumnMove
        initialCell={initialCell}
        contextMenuHidden={contextMenuHidden}
        onCallbackContextMenu={onCallbackContextMenu}
      />
    </>
  )
}

export default Sites

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { FontIcon, Spinner, SpinnerSize } from '@fluentui/react'
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog'
import { DefaultButton, PrimaryButton } from '../../common/Button'
import Subheader from '../../common/Subheader'
import { selectCreateBCFields } from '../../../features/bc/bcSlice'
import { findIndex } from '../../../features/network/indexing'
import {
  BC_CALC_METHOD_FIELD,
  BC_CASE_TYPE_FIELD,
  CALC_METHOD_NEW_SECTORS,
  CASE_TYPE_B2B,
} from '../../../features/bc/constants'
import ChoiceGroup from '../../common/ChoiceGroup'

import './EditCalcMethod.css'

const MIN_WIDTH = 600

export const EditCalcMethod = ({ show, onClose, onChange, bc }) => {
  const fields = useSelector(selectCreateBCFields)

  const caseTypeColIndex = useMemo(() => findIndex(fields, BC_CASE_TYPE_FIELD), [ fields ])
  const calcMethodColIndex = useMemo(() => findIndex(fields, BC_CALC_METHOD_FIELD), [ fields ])
  const [ type, setType ] = useState(null)
  const [ method, setMethod ] = useState(null)
  const modified = useMemo(() => {
    if (bc) {
      return type !== bc[caseTypeColIndex] || method !== bc[calcMethodColIndex]
    } else {
      return false
    }
  }, [ type, method, bc, caseTypeColIndex, calcMethodColIndex ])
  const { types, typesLabel, typesDescription } = useMemo(() => {
    const typeField = fields[caseTypeColIndex]
    return {
      typesLabel: typeField?.label,
      typesDescription: typeField?.description,
      types: typeField?.['enum-values']?.split(',').map((type) => ({
        key: type,
        text: type,
      })),
    }
  }, [ fields, caseTypeColIndex ])
  const { methods, methodsLabel, methodDescription } = useMemo(() => {
    const methodsField = fields[calcMethodColIndex]
    return {
      methodsLabel: methodsField?.label,
      methodDescription: methodsField?.description,
      methods: methodsField?.['enum-values']?.split(',').map((method) => ({
        key: method,
        text: method,
        disabled: type === CASE_TYPE_B2B && method !== CALC_METHOD_NEW_SECTORS,
      })),
    }
  }, [ fields, calcMethodColIndex, type ])

  const onChangeType = useCallback((event, { key: newType }) => {
    setType(newType)
    if (newType === CASE_TYPE_B2B && method !== CALC_METHOD_NEW_SECTORS) {
      setMethod(null)
      setTimeout(() => setMethod(CALC_METHOD_NEW_SECTORS), 10)
    }
  }, [ method ])

  const onChangeMethod = useCallback((event, { key: newMethod }) => {
    setMethod(newMethod)
  }, [])

  const handleOnSave = useCallback(() => {
    const newType = type !== bc[caseTypeColIndex] ? type : null
    const newMethod = method !== bc[calcMethodColIndex] ? method : null
    onChange(bc, newType, newMethod)
  }, [ onChange, bc, type, method, caseTypeColIndex, calcMethodColIndex ])

  const renderLabel = (label, description) => {
    return (
      <div className={'choice-group-label'} title={description}>
        {label}
        <FontIcon aria-label="Info" iconName="Info" />
      </div>
    )
  }

  const renderChoiceType = useMemo(() => {
    return (
      <ChoiceGroup
        className={'inlineflex'}
        defaultSelectedKey={type}
        options={types}
        label={renderLabel(typesLabel, typesDescription)}
        onChange={onChangeType}
      />
    )
  }, [ onChangeType, type, types, typesDescription, typesLabel ])

  const renderChoiceMethod = useMemo(() => {
    return method
      ? (
      <ChoiceGroup
        defaultSelectedKey={method}
        options={methods}
        label={renderLabel(methodsLabel, methodDescription)}
        onChange={onChangeMethod}
      />
        )
      : (
        <div className='choice-group-spinner'>
          <Spinner
            size={SpinnerSize.large}
          />
        </div>
        )
  }, [ method, methodDescription, methods, methodsLabel, onChangeMethod ])

  useEffect(() => {
    if (bc) {
      setType(bc[caseTypeColIndex])
      setMethod(bc[calcMethodColIndex])
    }
  }, [ bc, caseTypeColIndex, calcMethodColIndex ])

  return (
    <>
      <Dialog
        hidden={!show}
        onDismiss={onClose}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Edit calculation method',
        }}
        minWidth={MIN_WIDTH}
        modalProps={{
          isBlocking: true,
        }}
      >
        <Subheader text="Select the calculation method you want to use" />
        {renderChoiceType}
        {renderChoiceMethod}
        <DialogFooter>
          <PrimaryButton onClick={handleOnSave} disabled={!modified} text={'Save'} />
          <DefaultButton onClick={onClose} text={'Close'} />
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default EditCalcMethod
